/* @flow */

import { useIntl } from 'react-intl';

import SidebarCard from 'src/shared/app/base/component/template/component/sidebar/SidebarCard';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/components/domain/user/modals/l10n/phoneReminderL10n';
import SMSReminderModule from 'src/shared/components/domain/user/modals/phone-reminder/SMSReminderModule';
import Heading from 'src/shared/ui/component/text/Heading';

import styles from './SidebarSMSReminderNotifications.style';

const useStyles = createUseThemeStyles(styles);

const SidebarSMSReminderNotifications = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <SidebarCard title={intl.formatMessage(messages.recommend)}>
      <div className={classes.root}>
        <Heading level={3} layoutClass={classes.title}>
          {intl.formatMessage(messages.title)}
        </Heading>

        <SMSReminderModule
          content={
            <div className={classes.content}>
              <p>{intl.formatMessage(messages.sidebarParagraph1)}</p>
              <p>{intl.formatMessage(messages.sidebarParagraph2)}</p>
            </div>
          }
        />
      </div>
    </SidebarCard>
  );
};

export default SidebarSMSReminderNotifications;
