/* @flow */

import type { Node } from 'react';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './SidebarCard.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  title?: string,
  children: Node,
};

const SidebarCard = ({ title, children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title && <h2 className={classes.title}>{title}</h2>}

      {children}
    </div>
  );
};

export default SidebarCard;
