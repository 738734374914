/* @flow */

import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import designImg from 'src/shared/app/base/assets/images/prodideFeedback.png';
import SidebarCard from 'src/shared/app/base/component/template/component/sidebar/SidebarCard';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  loginLinkRoute,
  loginRoute,
  loginSupportRoute,
  passwordSupportRoute,
  resetPasswordRoute,
  retrieveUsernameRoute,
  signupRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import { getStaticAsset } from 'src/shared/app/base/util/assetUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/nav/l10n/navigationL10n.json';
import ChevronIcon from 'src/shared/ui/component/icon/ChevronIcon';

import styles from './SurveyCard.style';

const useStyles = createUseThemeStyles(styles);

const surveyLink = 'https://braindate.nolt.io/';

type Props = {
  showSurvey?: boolean,
};

const SurveyCard = ({ showSurvey }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { pathname } = useLocation();

  const routesThatShouldBeHidden = useRoutes(
    signupRoute,
    loginRoute,
    loginSupportRoute,
    retrieveUsernameRoute,
    loginLinkRoute,
    passwordSupportRoute,
    resetPasswordRoute,
  );

  const shouldBeHidden = routesThatShouldBeHidden.includes(pathname);

  if (!showSurvey || shouldBeHidden) {
    return null;
  }

  return (
    <SidebarCard title={intl.formatMessage(messages.provideFeedback)}>
      <div className={classes.root}>
        <img className={classes.img} src={getStaticAsset(designImg)} alt="" />

        <span className={classes.text}>
          {intl.formatMessage(messages.doBetter)}
        </span>

        <a
          href={surveyLink}
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          {intl.formatMessage(messages.share)}
          <ChevronIcon className={classes.linkChevron} />
        </a>
      </div>
    </SidebarCard>
  );
};

export default SurveyCard;
