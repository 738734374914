/* @flow */

import { memo } from 'react';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import ResponsivePicture from 'src/shared/ui/component/image/ResponsivePicture';

import styles from './SponsorItem.style';

type Props = {
  sponsor: {
    url: string,
    name: string,
    logo?: string,
    logoAltText?: string,
  },
};

const useStyles = createUseThemeStyles(styles);

const SponsorItem = ({ sponsor }: Props) => {
  const { url, name, logo, logoAltText } = sponsor;

  const classes = useStyles();

  return (
    <a href={url} className={classes.root} target="_blank" rel="noreferrer">
      {logo && (
        <ResponsivePicture
          className={classes.logo}
          picture={logo}
          alt={logoAltText}
          provideFallback
        />
      )}

      <h3 className={classes.name}>{name}</h3>
    </a>
  );
};

export default memo<Props>(SponsorItem);
