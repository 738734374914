/* @flow */

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    justifyContent: ({ hasSidebar }: ThemeFunction<{ hasSidebar: boolean }>) =>
      hasSidebar ? 'space-between' : 'center',

    [lap]: {
      flexDirection: 'row-reverse',
    },
  },
};
