/* @flow */

import { memo } from 'react';

import Fixed from 'src/shared/app/base/component/fixed/Fixed';
import useSidebar from 'src/shared/app/base/component/template/hooks/useSidebar';
import { utilityTopBarElevation } from 'src/shared/app/base/style/token/elevation';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import SidebarSMSReminderNotifications from 'src/shared/components/domain/user/modals/phone-reminder/SidebarSMSReminderNotifications';

import styles from './SideBar.style';
import SponsorsCards from './sponsors/SponsorsCards';
import SurveyCard from './survey/SurveyCard';

const useStyles = createUseThemeStyles(styles);

const Sidebar = () => {
  const {
    hasSidebar: shown,
    showSurvey,
    eventSponsors,
    showPhoneReminder,
  } = useSidebar();

  const classes = useStyles({ shown });

  return (
    <div className={classes.root}>
      <Fixed zIndex={utilityTopBarElevation + 1}>
        <aside className={classes.inner}>
          {showPhoneReminder ? <SidebarSMSReminderNotifications /> : null}

          <SponsorsCards eventSponsors={eventSponsors} />

          <SurveyCard showSurvey={showSurvey} />
        </aside>
      </Fixed>
    </div>
  );
};

export default memo<{}>(Sidebar);
