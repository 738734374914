/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: 142,
    marginBottom: 15,
  },
  text: {
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    fontSize: rem(15, baseFontSize),
    fontWeight: 700,
  },
  linkChevron: {
    marginLeft: 13,
    transform: 'rotate(270deg)',
    maxHeight: 9,
  },
};
