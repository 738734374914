/* @flow */

export default {
  root: {
    padding: '100px 10% 0',

    textAlign: 'center',
  },
  title: {
    fontSize: 20,
  },
};
